import PropTypes from "prop-types";
import { Link } from "../../../components/al_components/ALLink";
import IconArrow from "../../../components/icons/svgs/arrow.svg";
import * as Styles from "./aboutLink/styles.module.css";

function AboutLink({ to, children, className, isPdf = false }) {
  return isPdf ? (
    <a
      href={to}
      className={`${Styles.link} ${className || ""}`}
      target={isPdf ? "_blank" : "_self"}
      rel="noreferrer"
    >
      <p className="al_link">{children}</p>
      <IconArrow width="8" height="14" />
    </a>
  ) : (
    <Link to={to || "#"} className={`${Styles.link} ${className || ""}`}>
      <p className="al_link">{children}</p>
      <IconArrow width="8" height="14" />
    </Link>
  );
}

AboutLink.propTypes = {
  children: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
  isPdf: PropTypes.bool,
};

export default AboutLink;
