import PropTypes from "prop-types";
import { Link } from "../../../components/al_components/ALLink";
import useResponsiveWithHydrationFix from "../../../hooks/useResponsiveWithHydrationFix";
import * as Styles from "./scrollMenu/styles.module.css";

const ScrollMenu = ({ active }) =>
  useResponsiveWithHydrationFix(
    <>
      <div className={Styles.menu}>
        <div className={Styles.menu__item}>
          <Link to="/pages/our-journey">
            <p className={`al_p ${active === "journey" ? "al_p--bold" : ""}`}>Our Story</p>
          </Link>
        </div>
        <div className={Styles.menu__item}>
          <Link to="/pages/our-jewelry">
            <p className={`al_p ${active === "jewelry" ? "al_p--bold" : ""}`}>Our Jewelry</p>
          </Link>
        </div>
        <div className={Styles.menu__item}>
          <Link to="/pages/our-people">
            <p className={`al_p ${active === "people" ? "al_p--bold" : ""}`}>Our People</p>
          </Link>
        </div>
        <div className={Styles.menu__item}>
          <Link to="/pages/our-planet">
            <p className={`al_p ${active === "planet" ? "al_p--bold" : ""}`}>Our Planet</p>
          </Link>
        </div>
      </div>
      <div className={Styles.header}>
        <h1 className="al_p al_p--bold al_p--cap">Our {active}</h1>
      </div>
    </>,
    null
  );

ScrollMenu.propTypes = {
  active: PropTypes.string.isRequired,
};

export default ScrollMenu;
