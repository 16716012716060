import PropTypes from "prop-types";
import useResponsiveWithHydrationFix from "../../../hooks/useResponsiveWithHydrationFix";
import IconCloseRound from "../../../components/icons/svgs/close-round.svg";
import * as Styles from "./factoryCard/styles.module.css";

function FactoryCard({ factoryName, specialty, popupData, image, updatePopup }) {
  const popupImageSrc = useResponsiveWithHydrationFix(
    popupData ? popupData.image.mobile : null,
    popupData ? popupData.image.desktop : null
  );
  const cardImageSrc = useResponsiveWithHydrationFix(
    image ? image.mobile : null,
    image ? image.desktop : null
  );
  const responsiveLabel = useResponsiveWithHydrationFix("al_legend", "al_subtext");

  const headerMobile = (
    <h3 className={`al_h3 ${Styles.factory_card__content__specialty}`}>{specialty}</h3>
  );
  const headerDesktop = (
    <h4 className={`al_h4 ${Styles.factory_card__content__specialty}`}>{specialty}</h4>
  );
  const responsiveHeader = useResponsiveWithHydrationFix(headerMobile, headerDesktop);
  const popup = popupData ? (
    <div className={Styles.factory_card__popup_container}>
      <div className={Styles.factory_card__popup_container__popup}>
        <div
          className={Styles.factory_card__popup_container__popup__close}
          onClick={() => updatePopup(false)}
        >
          <IconCloseRound width="25" fill="none" stroke="#2D2927" />
        </div>
        <div className={Styles.factory_card__popup_container__popup__container}>
          <div className={Styles.factory_card__popup_container__popup__container__content}>
            <h2 className={Styles.factory_card__popup_container__popup__container__content__title}>
              {factoryName}
            </h2>
            <p
              className={`al_subtext ${Styles.factory_card__popup_container__popup__container__content__description}`}
            >
              {popupData && "description" in popupData && popupData.description
                ? popupData.description
                : null}
            </p>
            <table
              className={Styles.factory_card__popup_container__popup__container__content__table}
            >
              <tbody>
                {popupData.yearsInBusiness && (
                  <tr>
                    <th scope="row" className="al_subtext">
                      Years in business
                    </th>
                    <td className="al_subtext">{popupData.yearsInBusiness}</td>
                  </tr>
                )}
                {popupData.numberOfEmployees && (
                  <tr>
                    <th scope="row" className="al_subtext">
                      Number of employees
                    </th>
                    <td className="al_subtext">{popupData.numberOfEmployees}</td>
                  </tr>
                )}
                {popupData.employeeWorkingHoursPerDay && (
                  <tr>
                    <th scope="row" className="al_subtext">
                      Employee working hours per day
                    </th>
                    <td className="al_subtext">{popupData.employeeWorkingHoursPerDay}</td>
                  </tr>
                )}
                {popupData.paidOvertime && (
                  <tr>
                    <th scope="row" className="al_subtext">
                      Paid overtime
                    </th>
                    <td className="al_subtext">{popupData.paidOvertime}</td>
                  </tr>
                )}
                {popupData.employeeBenefits && (
                  <tr>
                    <th scope="row" className="al_subtext">
                      Employee benefits
                    </th>
                    <td className="al_subtext">{popupData.employeeBenefits}</td>
                  </tr>
                )}
                {popupData.certificationsAndAudits && (
                  <tr>
                    <th scope="row" className="al_subtext">
                      Certifications & audits
                    </th>
                    <td className="al_subtext">{popupData.certificationsAndAudits}</td>
                  </tr>
                )}
                {popupData.favoritePieces && (
                  <tr>
                    <th scope="row" className="al_subtext">
                      Favorite pieces
                    </th>
                    <td className="al_subtext">{popupData.favoritePieces}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className={Styles.factory_card__popup_container__popup__image_container}>
          <img
            src={popupImageSrc}
            className={Styles.factory_card__popup_container__popup__image_container__image}
            alt=""
          />
        </div>
      </div>
    </div>
  ) : null;

  return (
    <div className={Styles.factory_card}>
      <div className={Styles.factory_card__image_container}>
        <img src={cardImageSrc} className={Styles.factory_card__image_container__image} alt="" />
      </div>
      <div className={Styles.factory_card__content}>
        <h2 className="al_h2">{factoryName}</h2>
        <p className={`${responsiveLabel} ${Styles.factory_card__content__specialty_label}`}>
          Specialty
        </p>
        {responsiveHeader}
        {popupData ? (
          <button
            className={Styles.factory_card__content__button}
            onClick={() => updatePopup(true, popup)}
          >
            <p className="al_link">Read more</p>
          </button>
        ) : null}
      </div>
    </div>
  );
}

FactoryCard.propTypes = {
  factoryName: PropTypes.string.isRequired,
  specialty: PropTypes.string.isRequired,
  popupData: PropTypes.shape({
    description: PropTypes.string.isRequired,
    yearsInBusiness: PropTypes.number,
    numberOfEmployees: PropTypes.number,
    employeeWorkingHoursPerDay: PropTypes.number,
    paidOvertime: PropTypes.string,
    employeeBenefits: PropTypes.string,
    certificationsAndAudits: PropTypes.string,
    favoritePieces: PropTypes.string,
  }).isRequired,
  image: PropTypes.shape({
    mobile: PropTypes.string,
    desktop: PropTypes.string,
  }).isRequired,
  updatePopup: PropTypes.func.isRequired,
};

export default FactoryCard;
