import { useState } from "react";
import { Helmet } from "react-helmet";
import AboutLink from "./components/AboutLink";
import BannerSection from "./components/BannerSection";
import Collapsible from "./components/Collapsible";
import ExploreMore from "./components/ExploreMore";
import FactoryCarousel from "./components/FactoryCarousel";
import ScrollMenu from "./components/ScrollMenu";
import SideMenu from "./components/SideMenu";
import SEO from "../../components/seo";
import useResponsiveWithHydrationFix from "../../hooks/useResponsiveWithHydrationFix";

import firstImageD from "../../images/about_images/Our People Page/Desktop/OurPeople_hero_desktop.jpg";
import firstImageM from "../../images/about_images/Our People Page/Mobile/OurPeople_hero_mobile.jpg";
import secondImageD from "../../images/about_images/Our People Page/Desktop/OurPeople_tile2_desktop.jpg";
import secondImageM from "../../images/about_images/Our People Page/Mobile/OurPeople_tile2_mobile.jpg";
import thirdImageD from "../../images/about_images/Our People Page/Desktop/OurPeople_Tile3_desktop.jpg";
import thirdImageM from "../../images/about_images/Our People Page/Mobile/OurPeople_Tile3_mobile.jpg";
import infoD from "../../images/about_images/Our People Page/Desktop/OurPeople_infographic_desktop.jpg";
import infoM from "../../images/about_images/Our People Page/Mobile/OurPeople_infographic_mobile.jpg";

import pdfCodeOfConduct from "../../images/Ana Luisa - Vendor Code of Conduct.pdf";
import pdfAudit from "../../images/Ana Luisa - Audit Methodology.pdf";

import * as Styles from "./ourPeople/styles.module.css";

const menuItems = [
  {
    title: "Our Story",
    url: "/pages/our-journey",
    isActive: false,
  },
  {
    title: "Our Jewelry",
    url: "/pages/our-jewelry",
    isActive: false,
  },
  {
    title: "Our People",
    url: "/pages/our-people",
    isActive: true,
    subMenuItems: [
      {
        title: "Suppliers",
        url: "#suppliers",
      },
      {
        title: "Code of Conduct",
        url: "#code-of-conduct",
      },
      {
        title: "Audits",
        url: "#audits",
      },
      {
        title: "Giving Back",
        url: "#giving-back",
      },
    ],
  },
  {
    title: "Our Planet",
    url: "/pages/our-planet",
    isActive: false,
  },
];

function OurPeople({ path }) {
  const [popup, setPopup] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const updatePopup = (isOpen, popup) => {
    if (isOpen) {
      setIsPopupOpen(true);
      setPopup(popup);
    } else {
      setIsPopupOpen(false);
    }
  };
  const firstHeaderMobile = (
    <h1 className={`al_h3 ${Styles.our_people__content__mobile_title}`}>Our Certified Partners</h1>
  );
  const firstHeaderDesktop = <h1 className="al_h2">Our Certified Partners</h1>;
  const firstHeaderContent = useResponsiveWithHydrationFix(firstHeaderMobile, firstHeaderDesktop);

  const secondHeaderMobile = (
    <h3 className={`al_h3 ${Styles.our_people__content__mobile_title}`}>Jewelry with Standards</h3>
  );
  const secondHeaderDesktop = <h2 className="al_h2">Jewelry with Standards</h2>;
  const secondHeaderContent = useResponsiveWithHydrationFix(
    secondHeaderMobile,
    secondHeaderDesktop
  );

  const conductCollapsible = (
    <>
      <Collapsible title="Code of Conduct">
        <p className="al_p">
          Our objective is to conduct business at the highest level of ethical standards. In
          addition to abiding state and local requirements, all our vendors, factories, and
          subcontractors are expected to cooperate fully in accordance with our ever-expanding Code
          Of Conduct.
        </p>
        <br />
        <a href={pdfCodeOfConduct} className="al_link" target="_blank" rel="noreferrer">
          Read our Code of Conduct
        </a>
      </Collapsible>
      <Collapsible title="Audits" className={Styles.audit}>
        <p className="al_p">
          100% of our Tier-1 suppliers are audited and are in excellent compliance with our social
          and environmental standards.
        </p>
        <br />
        <p className="al_p">
          And it’s not just pen to paper. Together, with our partners, we’re taking meaningful
          action to better our supply chain. Through certified third parties, we conduct objective
          and measurable audits on how we’re doing. Then we implement new and improved measures to
          continuously heighten the expectations of ourselves and the industry. We make leading the
          way look good.
        </p>
        <br />
        <p className="al_p">
          {/* Check out the results from our latest audits <Link to="" className={Styles.audit_link}>here</Link>. */}
          Check out the results from our latest audits{" "}
          <a href={pdfAudit} className={Styles.audit_link} target="_blank" rel="noreferrer">
            here
          </a>
          .
        </p>
      </Collapsible>
    </>
  );
  const conductExpanded = (
    <>
      <h3 className={`al_h3 ${Styles.desktop_mobile_h3}`}>Code of Conduct</h3>
      <p className="al_p">
        Our objective is to conduct business at the highest level of ethical standards. In addition
        to abiding state and local requirements, all our vendors, factories, and subcontractors are
        expected to cooperate fully in accordance with our ever-expanding Code Of Conduct.
      </p>
      <AboutLink
        to={pdfCodeOfConduct}
        className={Styles.our_people__content__second_section__link}
        isPdf
      >
        Read our Code of Conduct
      </AboutLink>
      <h3 className={`al_h3 ${Styles.desktop_mobile_h3}`} id="audits">
        Audits
      </h3>
      <p className="al_p">
        100% of our Tier-1 suppliers are audited and are in excellent compliance with our social and
        environmental standards.
      </p>
      <br />
      <p className="al_p">
        And it’s not just pen to paper. Together, with our partners, we’re taking meaningful action
        to better our supply chain. Through certified third parties, we conduct objective and
        measurable audits on how we’re doing. Then we implement new and improved measures to
        continuously heighten the expectations of ourselves and the industry. We make leading the
        way look good.
      </p>
      <br />
      <p className="al_p">
        Check out the results from our latest audits{" "}
        <a href={pdfAudit} className="al_p--underline" target="_blank" rel="noreferrer">
          here
        </a>
        .
      </p>
    </>
  );
  const conductContent = useResponsiveWithHydrationFix(conductCollapsible, conductExpanded);

  const infographicSrc = useResponsiveWithHydrationFix(infoM, infoD);

  const givingCollapsible = (
    <div className={Styles.our_people__content__third_section__collapsibles}>
      <Collapsible title="Giving back">
        <p className="al_p">
          Giving back is the only way to move forward. That’s why we’re committed to supporting
          organizations that make our world a better place and to communities that need it the most.
          In 2022, we donated over $20,000 to causes near and dear to our team and our values. To
          stay active and involved in our local community, you can spot our Ana Luisa NYC team
          volunteering monthly with the North Brooklyn Angels, an NGO focused on providing meals for
          low-income families and food-insecure communities.
        </p>
      </Collapsible>
    </div>
  );
  const givingExpanded = (
    <>
      <h3
        className={`al_h3 ${Styles.our_people__content__third_section__giving} ${Styles.desktop_mobile_h3}`}
      >
        Giving back
      </h3>
      <p className="al_p">
        Giving back is the only way to move forward. That’s why we’re committed to supporting
        organizations that make our world a better place and to communities that need it the most.
        In 2022, we donated over $20,000 to causes near and dear to our team and our values. To stay
        active and involved in our local community, you can spot our Ana Luisa NYC team volunteering
        monthly with the North Brooklyn Angels, an NGO focused on providing meals for low-income
        families and food-insecure communities.
      </p>
      <br />
    </>
  );
  const givingContent = useResponsiveWithHydrationFix(givingCollapsible, givingExpanded);
  return (
    <div className={Styles.our_people}>
      <SEO
        title="Our People"
        description="Discover Ana Luisa's trusted partners for sustainable jewelry. Learn how we meticulously select our suppliers, ensuring ethical practices and eco-friendly materials. Explore our commitment to sustainability and shop with confidence, knowing you're supporting responsible craftsmanship."
      />
      <Helmet>
        <link rel="canonical" href={`https://www.analuisa.com${path}`} />
        <meta property="og:url" content={`https://www.analuisa.com${path}`} />
      </Helmet>
      <ScrollMenu active="people" />
      <SideMenu menuItems={menuItems} />
      <div className={Styles.our_people__content}>
        <BannerSection
          className={Styles.our_people__content__first_section}
          imageSrc={{ desktop: firstImageD, mobile: firstImageM }}
          imageAlt="Ana Luisa Jewelry Suppliers"
          landmark="suppliers"
        >
          {firstHeaderContent}
          <p className="al_p">
            Real impact doesn’t start and end with us alone. We’re proud to work with an incredible
            team of certified partners, experts, and scientists to uphold our values, keep ourselves
            accountable, and operate as responsibly and ethically as possible.
          </p>
          <br />
          <p className="al_p">
            We take full responsibility at every step—from sourcing to production, to quality
            testing and design—to ensure that every person within our value chain is treated with
            respect and dignity.
          </p>
          <FactoryCarousel
            className={Styles.our_people__content__factory_carousel}
            updatePopup={updatePopup}
          />
        </BannerSection>
        <BannerSection
          className={Styles.our_people__content__second_section}
          imageSrc={{ desktop: secondImageD, mobile: secondImageM }}
          imageAlt="Ana Luisa Jewelry Code Of Conduct"
          landmark="code-of-conduct"
        >
          {secondHeaderContent}
          <p className="al_p">
            When it comes to production, we follow a series of non-negotiables. Our thorough social
            audit guarantees every partner involved in the manufacturing process meets our social
            and environmental standards. And we don’t stop there: we also ensure our partners follow
            health and safety protocols, abide by women’s rights, and do not employ child labor or
            forced labor. We regularly monitor factories to ensure all employees feel safe, have the
            ability to voice concerns, and are treated fairly. This should be the industry standard.
          </p>
          <img
            src={infographicSrc}
            className={Styles.our_people__content__second_section__infographic}
            alt="Manufacturing process infographic"
          />
          {conductContent}
        </BannerSection>
        <BannerSection
          className={`${Styles.our_people__content__third_section}`}
          classNameContent={Styles.our_people__content__third_section__givingBack}
          imageSrc={{ desktop: thirdImageD, mobile: thirdImageM }}
          imageAlt="Ana Luisa Jewelry Giving Back"
          landmark="giving-back"
        >
          {givingContent}
        </BannerSection>
        <ExploreMore current="people" />
      </div>
      {isPopupOpen && popup}
    </div>
  );
}

export default OurPeople;
