import { useState } from "react";
import PropTypes from "prop-types";
import IconAdd from "../../../components/icons/svgs/add.svg";
import IconMinus from "../../../components/icons/svgs/minus.svg";
import * as Styles from "./collapsible/styles.module.css";

function Collapsible({ title, className, children }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={`${Styles.collapsible} ${className || ""}`}>
      <button
        className={Styles.collapsible__button}
        onClick={() => setIsOpen(!isOpen)}
        type="button"
      >
        <p className={`al_p ${isOpen && "al_p--bold"}`}>{title}</p>
        {isOpen ? <IconMinus width="30" stroke="#2d2927" /> : <IconAdd width="30" fill="#2d2927" />}
      </button>
      {isOpen && <div className={Styles.collapsible__content}>{children}</div>}
    </div>
  );
}

Collapsible.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default Collapsible;
