import PropTypes from "prop-types";

import FactoryCard from "./FactoryCard";

import "./factoryCarousel/about.scss";
import * as Styles from "./factoryCarousel/styles.module.css";

import shanImgD from "../../../images/about_images/Our People Page/Desktop/Factory Cards/Collapsed/OurPeople_ShanWei_Collapsed_desktop.jpg";
import shanImgM from "../../../images/about_images/Our People Page/Mobile/Factory Cards/Collapsed/OurPeople_ShanWei_Collapsed_mobile.jpg";
import shanImgPopupD from "../../../images/about_images/Our People Page/Desktop/Factory Cards/Expanded/OurPeople_ShanWei_Expanded.jpg";
import shanImgPopupM from "../../../images/about_images/Our People Page/Mobile/Factory Cards/Expanded/OurPeople_ShanWei_Expanded.jpg";

import panyuImgD from "../../../images/about_images/Our People Page/Desktop/Factory Cards/Collapsed/OurPeople_Panyu_Collapsed_desktop.jpg";
import panyuImgM from "../../../images/about_images/Our People Page/Mobile/Factory Cards/Collapsed/OurPeople_Panyu_Collapsed_mobile.jpg";
import panyuImgPopupD from "../../../images/about_images/Our People Page/Desktop/Factory Cards/Expanded/OurPeople_Panyu_Expanded.jpg";
import panyuImgPopupM from "../../../images/about_images/Our People Page/Mobile/Factory Cards/Expanded/OurPeople_Panyu_Expanded.jpg";

const factories = [
  {
    factoryName: "Panyu, China",
    specialty: "Hand-painted and stone setting",
    image: {
      desktop: panyuImgD,
      mobile: panyuImgM,
    },
    popupData: {
      description:
        "Our supplier in Panyu, China has been manufacturing high-end jewelry since 1996. Proud manufacturer of some of our best sellers like Celeste, Suzanne, Jusuf, our partner's facility is ISO certified.",
      yearsInBusiness: 26,
      numberOfEmployees: 580,
      employeeWorkingHoursPerDay: 8,
      paidOvertime: "Yes",
      employeeBenefits: "Pension, Unemployment, Medical, Maternity, Accident, Housing Funds",
      certificationsAndAudits: "ISO 9001, ISO 14001, ISO 17025, ISO 45001, SA8000, RJC COC",
      favoritePieces: "Letter Necklace, Cassie, Suzanne, Zara",
      image: {
        desktop: panyuImgPopupD,
        mobile: panyuImgPopupM,
      },
    },
  },
  {
    factoryName: "Shan Wei, China",
    specialty: "Sterling Silver delicate pieces",
    image: {
      desktop: shanImgD,
      mobile: shanImgM,
    },
    popupData: {
      description:
        "Established in 2008, our supplier in Shan Wei, China manufactures some of our best selling Sterling Silver pieces like the Sia earrings. Expanding every year, this facility now has more than 600 employees in a state-of-the-art 13,000-square-meter building.",
      yearsInBusiness: 14,
      numberOfEmployees: 600,
      employeeWorkingHoursPerDay: 8,
      paidOvertime: "Yes",
      employeeBenefits: "Pension, Unemployment, Medical, Maternity, Accident, Housing Fund",
      certificationsAndAudits: "ISO 9001, RJC COP, RJC COC",
      favoritePieces: "Frida, Toda, Mini Huggie Hoops, Mini Naomi, Giulia",
      image: {
        desktop: shanImgPopupD,
        mobile: shanImgPopupM,
      },
    },
  },
];

function FactoryCarousel({ className, updatePopup }) {
  return (
    <div className={`${Styles.factory_carousel} ${className || ""}`}>
      {factories.map(({ factoryName, specialty, popupData, image }) => (
        <FactoryCard
          factoryName={factoryName}
          specialty={specialty}
          popupData={popupData}
          updatePopup={updatePopup}
          image={image}
          key={factoryName}
        />
      ))}
    </div>
  );
}

FactoryCarousel.propTypes = {
  className: PropTypes.string,
  updatePopup: PropTypes.func.isRequired,
};

export default FactoryCarousel;
