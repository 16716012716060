// extracted by mini-css-extract-plugin
export var factory_card = "styles-module--factory_card--e207e";
export var factory_card__content = "styles-module--factory_card__content--2df98";
export var factory_card__content__button = "styles-module--factory_card__content__button--c7fa4";
export var factory_card__content__location = "styles-module--factory_card__content__location--d7e5c";
export var factory_card__content__specialty = "styles-module--factory_card__content__specialty--ec4a9";
export var factory_card__content__specialty_label = "styles-module--factory_card__content__specialty_label--36d6f";
export var factory_card__image_container = "styles-module--factory_card__image_container--e9d80";
export var factory_card__image_container__image = "styles-module--factory_card__image_container__image--81c33";
export var factory_card__popup_container = "styles-module--factory_card__popup_container--11f23";
export var factory_card__popup_container__popup = "styles-module--factory_card__popup_container__popup--eb4c4";
export var factory_card__popup_container__popup__close = "styles-module--factory_card__popup_container__popup__close--2cb72";
export var factory_card__popup_container__popup__container = "styles-module--factory_card__popup_container__popup__container--b00e2";
export var factory_card__popup_container__popup__container__content = "styles-module--factory_card__popup_container__popup__container__content--f7e2f";
export var factory_card__popup_container__popup__container__content__description = "styles-module--factory_card__popup_container__popup__container__content__description--e0117";
export var factory_card__popup_container__popup__container__content__table = "styles-module--factory_card__popup_container__popup__container__content__table--501dd";
export var factory_card__popup_container__popup__container__content__title = "styles-module--factory_card__popup_container__popup__container__content__title--975e6";
export var factory_card__popup_container__popup__image_container = "styles-module--factory_card__popup_container__popup__image_container--c9fd5";
export var factory_card__popup_container__popup__image_container__image = "styles-module--factory_card__popup_container__popup__image_container__image--cd432";