import PropTypes from "prop-types";
import useResponsiveWithHydrationFix from "../../../hooks/useResponsiveWithHydrationFix";
import * as Styles from "./bannerSection/styles.module.css";

function BannerSection({
  imageSrc = {},
  imageAlt = "",
  className,
  children,
  landmark,
  classNameContent,
}) {
  const contentSrc = useResponsiveWithHydrationFix(imageSrc.mobile, imageSrc.desktop);
  return (
    <div className={className || ""} id={landmark || ""}>
      <div className={Styles.banner_section__banner}>
        <img className={Styles.banner_section__banner__image} src={contentSrc} alt={imageAlt} />
      </div>
      <div className={`${Styles.banner_section__content} ${classNameContent || ""}`}>
        {children}
      </div>
    </div>
  );
}

BannerSection.propTypes = {
  image: PropTypes.shape({
    mobile: PropTypes.string,
    desktop: PropTypes.string,
  }).isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.element]),
  landmark: PropTypes.string,
};

export default BannerSection;
