import PropTypes from "prop-types";
import { Link } from "../../../components/al_components/ALLink";
import useResponsiveWithHydrationFix from "../../../hooks/useResponsiveWithHydrationFix";

// Images
import ourJewelryImgD from "../../../images/about_images/Explore More/Desktop/OurJewelry_thumb_desktop.jpg";
import ourJourneyImgD from "../../../images/about_images/Explore More/Desktop/OurJourney_thumb_desktop.jpg";
import ourPeopleImgD from "../../../images/about_images/Explore More/Desktop/OurPeople_thumb_desktop.jpg";
import ourPlanetImgD from "../../../images/about_images/Explore More/Desktop/OurPlanet_thumb_desktop.jpg";
import ourJewelryImgM from "../../../images/about_images/Explore More/Mobile/OurJewelry_thumb_mobile.jpg";
import ourJourneyImgM from "../../../images/about_images/Explore More/Mobile/OurJourney_thumb_mobile.jpg";
import ourPeopleImgM from "../../../images/about_images/Explore More/Mobile/OurPeople_thumb_mobile.jpg";
import ourPlanetImgM from "../../../images/about_images/Explore More/Mobile/OurPlanet_thumb_mobile.jpg";

import * as Styles from "./exploreMore/styles.module.css";

function ExploreMore({ current }) {
  const ourJourneyImgSrc = useResponsiveWithHydrationFix(ourJourneyImgM, ourJourneyImgD);
  const ourJewelryImgSrc = useResponsiveWithHydrationFix(ourJewelryImgM, ourJewelryImgD);
  const ourPeopleImgSrc = useResponsiveWithHydrationFix(ourPeopleImgM, ourPeopleImgD);
  const ourPlanetImgSrc = useResponsiveWithHydrationFix(ourPlanetImgM, ourPlanetImgD);
  const responsiveH2 = useResponsiveWithHydrationFix("al_h3", "al_h2");
  const responsiveH4 = useResponsiveWithHydrationFix("al_p", "al_h4");

  return (
    <div className={Styles.explore}>
      <h2 className={responsiveH2}>Explore more</h2>
      <div className={Styles.explore__container}>
        {current !== "journey" && (
          <Link className={Styles.explore__container__card} to="/pages/our-journey">
            <div className={Styles.explore__container__card__image}>
              <img src={ourJourneyImgSrc} alt="Ana Luisa Jewelry - Our Journey" />
            </div>
            <h4 className={responsiveH4}>Our Story</h4>
          </Link>
        )}
        {current !== "jewelry" && (
          <Link className={Styles.explore__container__card} to="/pages/our-jewelry">
            <div className={Styles.explore__container__card__image}>
              <img src={ourJewelryImgSrc} alt="Ana Luisa Jewelry - Our Jewelry" />
            </div>
            <h4 className={responsiveH4}>Our Jewelry</h4>
          </Link>
        )}
        {current !== "people" && (
          <Link className={Styles.explore__container__card} to="/pages/our-people">
            <div className={Styles.explore__container__card__image}>
              <img src={ourPeopleImgSrc} alt="Ana Luisa Jewelry - Our People" />
            </div>
            <h4 className={responsiveH4}>Our People</h4>
          </Link>
        )}
        {current !== "planet" && (
          <Link className={Styles.explore__container__card} to="/pages/our-planet">
            <div className={Styles.explore__container__card__image}>
              <img src={ourPlanetImgSrc} alt="Ana Luisa Jewelry - Our Planet" />
            </div>
            <h4 className={responsiveH4}>Our Planet</h4>
          </Link>
        )}
      </div>
    </div>
  );
}

ExploreMore.propTypes = {
  current: PropTypes.string.isRequired,
};

export default ExploreMore;
