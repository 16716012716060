// extracted by mini-css-extract-plugin
export var audit = "styles-module--audit--e5c0a";
export var audit_link = "styles-module--audit_link--bc6dc";
export var desktop_mobile_h3 = "styles-module--desktop_mobile_h3--c693e";
export var our_people = "styles-module--our_people--fe059";
export var our_people__content = "styles-module--our_people__content--77a58";
export var our_people__content__factory_carousel = "styles-module--our_people__content__factory_carousel--3bb3d";
export var our_people__content__first_section = "styles-module--our_people__content__first_section--f6902";
export var our_people__content__mobile_title = "styles-module--our_people__content__mobile_title--47d6d";
export var our_people__content__second_section = "styles-module--our_people__content__second_section--9f212";
export var our_people__content__second_section__infographic = "styles-module--our_people__content__second_section__infographic--8cd9b";
export var our_people__content__second_section__link = "styles-module--our_people__content__second_section__link--478d6";
export var our_people__content__third_section = "styles-module--our_people__content__third_section--22ab5";
export var our_people__content__third_section__collapsibles = "styles-module--our_people__content__third_section__collapsibles--368a4";
export var our_people__content__third_section__conduct = "styles-module--our_people__content__third_section__conduct--c6df6";
export var our_people__content__third_section__giving = "styles-module--our_people__content__third_section__giving--95f48";
export var our_people__content__third_section__givingBack = "styles-module--our_people__content__third_section__giving-back--87aec";